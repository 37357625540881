import React, {useState, useEffect} from "react";

import "./login.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Arrow from '../../assets/LongArrow.png';
import Logo from '../../assets/Smart_farm_login.png';
import Person from '../../assets/person.svg';
import Lock from '../../assets/lock.svg';
import { connect } from "react-redux";
import {loginRequest} from '../../store/action/loginAction';

const Login = ({loginSubmit, isLogged, ...props}) => {
  const [inputUserName,setInputUserName]=useState("");
  const [inputPassword, setInputPassword] =useState("");
  const [type,setType]=React.useState("password");
  const [icon,setIcon]=React.useState(<VisibilityOffIcon/>);

  const navigate= useNavigate();

  useEffect(()=>{
    if(isLogged){
      if(isLogged.status === false){
        Swal.fire({
          icon: 'error',
          text: 'Please Enter Correct Username and Password',
          confirmButtonColor: '#F37021',
          timer: 3000
        })
      }else{
        navigate('/dashboards');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isLogged])

  const handleToggle=()=>{
        if(type === 'password'){
          setIcon(<VisibilityIcon/>);
          setType("text");
        }else{
          setIcon(<VisibilityOffIcon/>);
          setType("password");
        }
    }

  const handleSubmit=()=>{
    if(inputUserName !== "" && inputPassword !== ""){
      let requestUser={
        username:inputUserName,
        password: inputPassword
      };
      loginSubmit({requestUser});
    }else{
      Swal.fire({
        icon: 'error',
        text: 'Please Enter Username and Password',
        confirmButtonColor: '#F37021',
        timer: 3000
      })
    }
  }

  return (
    <div className="rootLogin">
      <div className="rootLeft-wrapper">
      <div className="bottomImage"></div>
      <div className="leftImage"></div>
        <div className="rootLeft">
        <div className="logo">
            <img src={Logo} alt="simha kit logo" />
          </div>
          <div className="intro">
            <h2>Welcome Back</h2>
            <p>Welcome to Smart Farm Analytics Portal</p>
          </div>
          <h3 className="logIn">Log In</h3>
          <div className="inputWrapper">
              <img src={Person} alt="person"/>
              <input 
                type="text"
                className="inputBox"
                value={inputUserName}
                placeholder="UserName"
                onChange={(e) => setInputUserName(e.target.value)}/>
          </div>
          <div className="inputWrapper">
              <img src={Lock} alt="lock"/>
              <input 
                type={type}
                className="inputBox"
                value={inputPassword}
                placeholder="Password"
                onChange={(e) => setInputPassword(e.target.value)}/>
              <span onClick={handleToggle} className="eyeIcon">{icon}</span>
          </div>
          <div className="submitButton" onClick={handleSubmit}>
            <p>Login</p>
            <span><img src={Arrow} alt="arrow"/></span>
          </div>
        </div>
      </div>
      <div className="rootRight"></div>
    </div>
  );
};

const mapStateToProps=(state) => ({
  isLogged: state.loginReducer.userData,
})

const mapDispatchToProps = (dispatch) => ({
  loginSubmit: ({ requestUser }) =>
    dispatch(loginRequest({ requestUser })),
});


export default connect(mapStateToProps,mapDispatchToProps)(Login);
